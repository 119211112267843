import { FeatureFlag } from "src/utils/featureFlag/types";
import {getCountryCode} from "src/utils/apis/apiHelper";

export const getFeatureStatus = (feature: FeatureFlag, override?: string) => {
    try {
        const featureFlagConfig = JSON.parse((override ? override : '{{FeatureFlag}}').replace(/\\"/g, '"'));

        if (typeof featureFlagConfig !== "object") {
            return  false;
        }

        const countryCode = getCountryCode().toLowerCase();
        if (!featureFlagConfig[countryCode] || !featureFlagConfig[countryCode][feature]) {
            return false;
        }

        return featureFlagConfig[countryCode][feature];
    } catch (e) {
        return false;
    }
};
export const isStencil2021Theme = () => (getFeatureStatus(FeatureFlag.theme) === 'stencil-2021');
export const isPreferredNameEnabled = () => getFeatureStatus(FeatureFlag.preferredName);
export const isEnglishNameEnabled = () => getFeatureStatus(FeatureFlag.englishName);
export const shouldUseBBSignOut = () => getFeatureStatus(FeatureFlag.useBBSignOut);
export const isTokenCookieEnabled = () => getFeatureStatus(FeatureFlag.tokenCookie);
export const isMultiLanguageEnabled = () => getFeatureStatus(FeatureFlag.multiLanguage);
export const isSfOnDemandSyncEnabled = () => getFeatureStatus(FeatureFlag.sfOnDemandSync);
export const isMiddleNameEnabled = () => getFeatureStatus(FeatureFlag.middleName);
export const isSuffixEnabled = () => getFeatureStatus(FeatureFlag.suffix);
export const isFamilyNameFirstEnabled = () => getFeatureStatus(FeatureFlag.familyNameFirst);
export const isEmailFieldOptional = () => getFeatureStatus(FeatureFlag.isEmailOptional);
export const isRightToLeftTextEnabled = () => getFeatureStatus(FeatureFlag.rightToLeftText);
export const isAgencyHiringEnabled = () => getFeatureStatus(FeatureFlag.isAgencyHiring);