import React, {useEffect, useRef, useState} from "react";
import {Button, ButtonVariant} from "@amzn/stencil-react-components/button";
import {Col, Row} from "@amzn/stencil-react-components/layout";
import {Link} from "@amzn/stencil-react-components/link";
import {Status, StatusIndicator, StatusIndicatorColorScheme,} from "@amzn/stencil-react-components/status-indicator";
import {H1, Text} from "@amzn/stencil-react-components/text";
import debounce from "lodash/debounce";
import isBoolean from "lodash/isBoolean";
import isEmpty from "lodash/isEmpty";
import {connect} from "react-redux";
import {useHistory} from "react-router";
import {boundGetConfig, boundGetCSRF, boundRemoveAlerts, boundUpdateRegistrationForm} from "src/actions/boundActions";
import {initCandidate} from "src/reducers/candidate.reducer";
import {getCountryFullName} from "src/utils/apis/apiHelper";
import {
  Candidate,
  CountryFullName,
  RegistrationForm,
  RegistrationFormProperty,
  UpdateFromTarget
} from "src/utils/commonTypes";
import {REGISTRATION_PAGES, USER_SIGN_UP_TYPE} from "src/utils/constants";
import {AuthErrorMessages} from "src/utils/errorMessages";
import {
  isEnglishNameEnabled,
  isMiddleNameEnabled,
  isPreferredNameEnabled,
  isSuffixEnabled,
  isFamilyNameFirstEnabled,
  isEmailFieldOptional
} from "src/utils/featureFlag/featureFlag";
import {
  focusFirstInputErrorItem,
  generateFocusableItemClassesForRegistration,
  setRegistrationAsLoginFlow,
  updateRegistrationFormOrPhoneNumberInput
} from "src/utils/helper";
import {FormConfig, PhoneCodes, PreferredLanguages} from "src/utils/registrationFrom/constants";
import {onCreateCandidate,} from "src/utils/registrationService";
import {translate as t} from "../../utils/translator";
import {PAGE_ROUTE_LOGIN, PAGE_ROUTE_VERIFY_EMAIL, PAGE_ROUTE_VERIFY_PHONE} from "../pageRoutes";
import Consents from "./ConsentsComponent";
import InputComponent from "./InputComponent";
import PhoneNumberInputComponent from "./PhoneNumberInputComponent";
import SelectComponent from "./SelectComponent";
import {addAdobeMetric} from "src/customerTracking/adobeAnalytics";
import {AdobeEvent, PageName} from "src/customerTracking/adobeAnalytics/types";
import {recordPageView} from "src/utils/rum";
import { CheckboxComponent } from "./CheckboxComponent";
import CheckboxControlledInputComponent from "./CheckboxControlledInputComponent";
import { swapComponents } from "src/utils/registrationFrom/registrationHelper";

interface RegistrationPageProps {
  validationErrorsOverride?: boolean;
  signUpType?: string;
  candidate?: { results: Candidate };
}

interface MapStateToProps {
  registration: RegistrationForm;
}

type RegistrationPageMergedStateProps = RegistrationPageProps & MapStateToProps;

export const RegistrationPage = (
  props: RegistrationPageMergedStateProps
) => {
  const history: any = useHistory();
  const signUpType = props.signUpType? props.signUpType : USER_SIGN_UP_TYPE.CREATE;
  const candidate = props.candidate ? props.candidate.results : initCandidate;
  let registrationForm = props.registration;
  const [isRegistrationHasValidationErrors, setIsRegistrationHasValidationErrors] = useState(
    isBoolean(props.validationErrorsOverride)
      ? props.validationErrorsOverride
      : false
  );
  const updateForm = (targets: UpdateFromTarget[]) => {
    updateRegistrationFormOrPhoneNumberInput(registrationForm, targets, boundUpdateRegistrationForm);
  };

  const toggleMiddleName = (isChecked: boolean) => {
    setIsDisabled((isDisabled) => isChecked)
    if (isChecked) {
      // update these fields in redux store to empty string if checkbox is checked
      let middleNameTargets: UpdateFromTarget[] = [
        {key: "middleName" as RegistrationFormProperty, value: ''},
      ]
      updateForm(middleNameTargets);
    }
  };

  const handleNoneSuffix = (targets: any) => {
    //Pass empty string to redux store if None is selected. This avoids having suffix stored as None in CDS/SF
    if(targets[0].value == 'None')
      targets = [{...targets[0], value: ""}]
    updateRegistrationFormOrPhoneNumberInput(registrationForm, targets, boundUpdateRegistrationForm);
  }

  const goToLoginPage = () => {
    history.push(`/${PAGE_ROUTE_LOGIN}`);
  };

  const goToVerifyEmailPage = () => {
    history.push(`/${PAGE_ROUTE_VERIFY_EMAIL}`);
  };
  const goToVerifyPhonePage = () => {
    history.push(`/${PAGE_ROUTE_VERIFY_PHONE}`);
  };
  const initFormConfig = FormConfig.Registration;
  const initVerifyFormConfig = FormConfig.Verify;
  const [currentFormConfig, setCurrentFormConfig] = useState(initFormConfig);
  const [currentVerifyFormConfig, setCurrentVerifyFormConfig] = useState(initVerifyFormConfig);
  const [consentError, setConsentError] = useState<boolean[]>([false, false]);
  const [hasFocusedError, setHasFocusedError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false)
  const consentToggleRef1 = useRef<HTMLButtonElement>(null);
  const consentToggleRef2 = useRef<HTMLButtonElement>(null);
  const consentToggleRef3 = useRef<HTMLButtonElement>(null);

  const focusErrorItem = () => {
    const focusResult = focusFirstInputErrorItem(() => setHasFocusedError(true));
    if (!focusResult) {
      // Check consent error next if no error in form
      if (consentError[0]) {
        consentToggleRef1?.current?.focus();
        setHasFocusedError(true);
      } else if (consentError[1]) {
        consentToggleRef2?.current?.focus();
        setHasFocusedError(true);
      } else if (consentError[2]) {
        consentToggleRef3?.current?.focus();
        setHasFocusedError(true);
      }
    }
  };

  useEffect(() => {
    !hasFocusedError && focusErrorItem(); // Stop if already focused the error once.
  });

  useEffect(() => {
    boundRemoveAlerts();
    boundGetConfig();
    boundGetCSRF();
    addAdobeMetric(AdobeEvent.CREATE_ACCOUNT, PageName.REGISTER);


    // Exclude preferred name fields from configs If preferred name is disabled
    let newInitFormConfig = isPreferredNameEnabled() ?
      currentFormConfig : currentFormConfig.filter(config => !config.isPreferredName);
    if (!isEnglishNameEnabled()) {
      newInitFormConfig = newInitFormConfig.filter(config => !config.isEnglishName);
    }
    if (!isMiddleNameEnabled()) {
      newInitFormConfig = newInitFormConfig.filter(config => !config.isMiddleName);
    }
    if (!isSuffixEnabled()) {
      newInitFormConfig = newInitFormConfig.filter(config => !config.isSuffix)
    }
    if (isFamilyNameFirstEnabled()) {
      const firstNameIndex = newInitFormConfig.findIndex((config => {
        return config.id === 'firstName'
      }));
      const familyNameIndex = newInitFormConfig.findIndex((config => {
        return config.id === 'lastName'
      }));
      newInitFormConfig = swapComponents(newInitFormConfig, firstNameIndex, familyNameIndex);
    }
    if (isEmailFieldOptional()){
      const email = newInitFormConfig.findIndex((config => {
        return config.id === 'emailId'
      }));
      const reEnterEmail = newInitFormConfig.findIndex((config => {
        return config.id === 'reEnterEmailId'
      }));
      newInitFormConfig[email].required = false
      newInitFormConfig[reEnterEmail].required = false
    }

    setCurrentFormConfig(newInitFormConfig);

    if (signUpType === USER_SIGN_UP_TYPE.VERIFY) {
      if (isEmpty(candidate.candidateLogin)) {
        goToLoginPage();
      }

      registrationForm = { ...registrationForm, ...candidate };

      // fix sev2, salesforce existing candidate with phoneCountryCode null override the phoneCountryCode
      if (getCountryFullName() === CountryFullName.US || getCountryFullName() === CountryFullName.CA) {
        registrationForm.phoneCountryCode = "+1";
      } else if (getCountryFullName() === CountryFullName.MX) {
        registrationForm.phoneCountryCode = "+52";
      } else if (getCountryFullName() === CountryFullName.JP) {
        registrationForm.phoneCountryCode = "+81";
      }
    }
    const countryFullName = getCountryFullName();
    registrationForm.phoneCountryCode = PhoneCodes[countryFullName][0];
    registrationForm.country = countryFullName;
    if (countryFullName === CountryFullName.JP){
      registrationForm.language = PreferredLanguages[countryFullName][0];
    }
    boundUpdateRegistrationForm(registrationForm);
  }, []);
  const createCandidate = () => {
    setRegistrationAsLoginFlow();

    onCreateCandidate({
      registrationForm: registrationForm,
      formConfig: signUpType === USER_SIGN_UP_TYPE.CREATE? currentFormConfig : currentVerifyFormConfig,
      formConsent: [registrationForm.isAgreeToDataRetention, registrationForm.isAgreeToCommunication, registrationForm.isWhatsAppEnabled],
      signUpType: signUpType,
      onCheckForm: signUpType === USER_SIGN_UP_TYPE.CREATE? setCurrentFormConfig : setCurrentVerifyFormConfig,
      onCheckConsent: setConsentError,
      onCheckOverall: setIsRegistrationHasValidationErrors,
      onNext: isEmailFieldOptional() ? goToVerifyPhonePage : goToVerifyEmailPage,
    });
  };

  const debounceCreateCandidate = debounce(() => {
    setHasFocusedError(false); // Reset error focus for the next form check.
    createCandidate();
  }, 1000);

  const setIsAgreeToCommunication = (isAgreeToCommunication: boolean) => {
    const targets = [
      { key: "isAgreeToCommunication" as RegistrationFormProperty, value: isAgreeToCommunication }
    ];
    updateForm(targets);
  };

  const setIsAgreeToDataRetention = (isAgreeToDataRetention: boolean) => {
    const targets = [
      { key: "isAgreeToDataRetention" as RegistrationFormProperty, value: isAgreeToDataRetention }
    ];
    updateForm(targets);
  };

  const setIsWhatsAppEnabled = (isWhatsAppEnabled: boolean) => {
    const targets = [
      { key: "isWhatsAppEnabled" as RegistrationFormProperty, value: isWhatsAppEnabled }
    ];
    updateForm(targets);
  };

  const registrationFrom = currentFormConfig.map((config, index) => {
    const id = config.id as RegistrationFormProperty;
    if (config.type === "select") {
      // TODO: add separate feature flag for timezone and language visible or not
      if ((getCountryFullName() === CountryFullName.UK) && config.name === "timezone") {
        return null;
      }
      if ((getCountryFullName() === CountryFullName.JP && config.name === "language")) {
        return null;
      }
      return (
        <SelectComponent
          key={`${id}_${index}_component`}
          onChange={config.id === 'nameSuffix'? handleNoneSuffix : updateForm}
          placeholder={t(config.placeholderKey || "", config.placeholder || "")}
          type={config.type}
          id={id}
          value={registrationForm[id]}
          labelText={t(config.labelTextKey, config.labelText)}
          options={config.options}
          index={index}
          required={config.required}
          hasError={config.hasError}
          errorText={config.errorText}
        />
      )
      } else if (config.type === "tel") {
      return (
        <PhoneNumberInputComponent
          key={`${id}_${index}_component`}
          onChange={updateForm}
          placeholder={t(config.placeholderKey || "", config.placeholder || "")}
          type={config.inputType}
          id={id}
          value={registrationForm[id] as string}
          countryCode={registrationForm["phoneCountryCode"]}
          labelText={t(config.labelTextKey, config.labelText)}
          required={true}
          options={config.options}
          hasError={config.hasError}
          errorText={config.errorText}
          disablePaste={config.disablePaste}
          name={config.name}
        />
      )
    } else if (config.type === "checkboxControlledText") {
      if (config.checkboxLabelTextKey) {
        return (
          <CheckboxControlledInputComponent
            key={`${id}_${index}_component`}
            onChange={updateForm}
            placeholder={t(config.placeholderKey || "", config.placeholder || "")}
            required={true}
            inputType={config.inputType}
            id={id}
            value={registrationForm[id] as string}
            labelText={t(config.labelTextKey, config.labelText)}
            checkboxLabelText={t(config.checkboxLabelTextKey, config.checkboxLabelText)}
            hasError={config.hasError}
            errorText={config.errorText}
            tooltipText={config.tooltipText}
            maxLength={config.maxLength}
            disablePaste={config.disablePaste}  
            />)
        }
    } else if (config.type === "checkbox") {
      return (
        <CheckboxComponent
          id={config.id}
          onChange={toggleMiddleName}
          labelText={t(config.labelTextKey, config.labelText)}
        />
      )
    } 
        return (
          <InputComponent
            key={`${id}_${index}_component`}
            onChange={updateForm}
            placeholder={t(config.placeholderKey || "", config.placeholder || "")}
            required={config.required}
            inputType={config.inputType}
            id={id}
            value={registrationForm[id] as string}
            labelText={t(config.labelTextKey, config.labelText)}
            hasError={config.hasError}
            errorText={config.errorText}
            tooltipText={config.tooltipText}
            maxLength={config.maxLength}
            disablePaste={config.disablePaste}
          />
        ) 
  });

  const verifyRegistrationFrom = currentVerifyFormConfig.map((config, index) => {
    const id = config.id as RegistrationFormProperty;
    if (config.type === "select") {
      return (
        <SelectComponent
          key={`${id}_component_verify`}
          onChange={updateForm}
          placeholder={t(config.placeholderKey || "", config.placeholder || "")}
          type={config.type}
          id={id}
          value={registrationForm[id]}
          labelText={t(config.labelTextKey, config.labelText)}
          options={config.options}
          index={index}
          required
          hasError={config.hasError}
          errorText={config.errorText}
        />
      );
    } else if (config.type === "tel") {
      return (
        <PhoneNumberInputComponent
          key={`${id}_component_verify`}
          onChange={updateForm}
          placeholder={t(config.placeholderKey || "", config.placeholder || "")}
          type={config.inputType}
          id={id}
          value={registrationForm[id] as string}
          countryCode={registrationForm["phoneCountryCode"]}
          labelText={t(config.labelTextKey, config.labelText)}
          required
          options={config.options}
          hasError={config.hasError}
          errorText={config.errorText}
          disablePaste={config.disablePaste}
        />
      );
    }
    return (
      <InputComponent
        key={`${id}_component_verify`}
        onChange={updateForm}
        placeholder={t(config.placeholderKey || "", config.placeholder || "")}
        required={config.required}
        inputType={config.inputType}
        id={id}
        value={registrationForm[id] as string}
        labelText={t(config.labelTextKey, config.labelText)}
        hasError={config.hasError}
        errorText={config.errorText}
        tooltipText={config.tooltipText}
        maxLength={config.maxLength}
        disabled={config.disabled}
        disablePaste={config.disablePaste}
        spellCheck={config.spellCheck}
      />
    );
  });

  const registrationTitleText = t("RegistrationPage-title-hvh-registration", "Create an account");
  const verifyTitleText = t("RegistrationPage-subtitle-applying-for-job", "Applying for a job just got easier.");
  const verifyText = t("RegistrationPage-subtitle-verify-personal-info-msg", "We are improving the job application experience! We take the security of your personal information very seriously, so before you get on board, we need to verify your personal information.");

  const signUpHeader = signUpType === USER_SIGN_UP_TYPE.CREATE? registrationTitleText : verifyTitleText;
  const signUpText = signUpType === USER_SIGN_UP_TYPE.CREATE? verifyTitleText : verifyText;

  recordPageView({pageId: REGISTRATION_PAGES.REGISTRATION});
  return (
    <>
      <Row justifyContent="center">
        <Col
          className="hvhRegistration"
          dataTestId="registration-placeholder"
          gridGap="S300"
          padding="0px 10px"
        >
          <Col className="colContainerTop">
            <H1 fontSize="T400" color="accent1" fontWeight="regular">
              {signUpHeader}
            </H1>
            <Text
              color="accent1"
              fontSize="T100"
              textAlign="left"
            >
              {signUpText}
            </Text>
          </Col>
          {signUpType === USER_SIGN_UP_TYPE.CREATE? registrationFrom : verifyRegistrationFrom}
          <Consents
            registrationForm={registrationForm}
            setIsAgreeToDataRetention={setIsAgreeToDataRetention}
            setIsAgreeToCommunication={setIsAgreeToCommunication}
            setIsWhatsAppEnabled={setIsWhatsAppEnabled}
            consentError={consentError}
            consentToggleRef1={consentToggleRef1}
            consentToggleRef2={consentToggleRef2}
          />
          {isRegistrationHasValidationErrors && (
            <StatusIndicator
              status={Status.Negative}
              colorScheme={StatusIndicatorColorScheme.Default}
              messageText={t(AuthErrorMessages["registration-validation-error"].key, AuthErrorMessages["registration-validation-error"].value)}
            />
          )}
          <Col className="colContainer" gridGap="S300" padding={{ top: "24px", bottom: "48px" }}>
            <Row
              width="100%"
              alignItems={"center"}
              gridGap={8}
            >
              <Text
                color="accent1"
                fontSize="T200"
                textAlign="justify"
              >
                {t("RegistrationPage-lbl-have-an-account", "Already have an account?")}
              </Text>
              <Link
                dataTestId="link-backToLogin"
                onClick={() => goToLoginPage()}
                className={generateFocusableItemClassesForRegistration()}
                tabIndex={0}
              >{t("RegistrationPage-link-sign-in", "Sign in")}
              </Link>
            </Row>
            <Button
              dataTestId="button-test-id-createCandidate"
              onClick={() => debounceCreateCandidate()}
              variant={ButtonVariant.Primary}
            >
              {t("RegistrationPage-lbl-Continue-button", "Continue")}
            </Button>
          </Col>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state: MapStateToProps) => {
  return state;
};

export default connect(mapStateToProps)(RegistrationPage);
