import React from "react";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { Text } from "@amzn/stencil-react-components/text";
import { IconRefresh } from "@amzn/stencil-react-components/icons";
import { translateWithFormat as t } from "../../utils/translator";
import { onResendOtp } from "../../utils/authService";
import isBoolean from "lodash/isBoolean";
import isNumber from "lodash/isNumber";

interface Props {
  resetOtpTextBox?: Function
  disableResendButtonOverride?: boolean
  candidate: any
  waitTime?: number
  setIntervalCallback?: Function
  loginType: string
  pin: string
}

export class ResendOtp extends React.Component<Props, any> {
  resendOtpInterval: any;

  constructor(props: Props){
    super(props);
    this.state={
      disableResendButton: isBoolean(props.disableResendButtonOverride)? props.disableResendButtonOverride : true,
      seconds: props.waitTime? props.waitTime : 30 
    };
    this.onResendOtpClick = this.onResendOtpClick.bind(this);
    this.enableResendButton = this.enableResendButton.bind(this);
  }

  componentDidMount(){
    this.enableResendButton();
  }

  componentWillUnmount(){
    clearInterval(this.resendOtpInterval);
  }

  onResendOtpClick(){
    this.setState({
      disableResendButton:true
    });
    this.props.resetOtpTextBox && this.props.resetOtpTextBox();
    onResendOtp({candidate: this.props.candidate, loginType: this.props.loginType, pin:this.props.pin});
    this.enableResendButton();
  };

  enableResendButton(){
    this.resendOtpInterval = setInterval(() => {
      let newSeconds;
      if (this.state.seconds < 1) {
        this.setState({
          disableResendButton:false
        });
        clearInterval(this.resendOtpInterval);
        newSeconds = isNumber(this.props.waitTime)? this.props.waitTime : 30;
      } else {
        newSeconds = this.state.seconds - 1;
      }
      this.setState({
        seconds:newSeconds
      });
      this.props.setIntervalCallback && this.props.setIntervalCallback();
    }, 1000);
  };

  render(){
    return (
      <Col gridGap="S300" justifyContent="flex-start">
      {!this.state.disableResendButton ? (
        <Row>
          <Button 
              onClick={this.onResendOtpClick}
              className="resendOtpButton"
              dataTestId="button-test-id-resendOtpButton"
              variant={ButtonVariant.Tertiary}
          >
            <Row 
              gridGap={"S300"}
              alignItems="center"
            >
              <IconRefresh aria-hidden={true} />
              <Text>
                {t("ResendOtpComponent-btn-resend-label", "Resend verification code")}
              </Text>
            </Row>
          </Button>
        </Row>
      ) : (
        <Text fontSize="T100">
          {t(
            "ResendOtpComponent-lbl-resend-code",
            "You can resend verification code in {seconds} seconds",
            {seconds: `${this.state.seconds}`}
          )}
        </Text>
      )}
    </Col>
    );
  }
}

export default ResendOtp;
