import React from "react";
import isEmpty from "lodash/isEmpty";
import { Label, Text } from "@amzn/stencil-react-components/text";
import {
  InputWrapper,
  Select,
} from "@amzn/stencil-react-components/form";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { translate as t } from "../../utils/translator";

interface SelectProps {
  hasError?: boolean;
  errorText?: { [key: string]: string } | string;
  infoText?: string;
  tooltipText?: string;
  labelText: string;
  type?: string;
  id?: string;
  value: any;
  onChange?: Function;
  name?: string;
  placeholder?: string;
  optionalText?: string;
  required?: boolean;
  properties?: any;
  disabled?: boolean;
  options?: any;
  index?: number;
}

const SelectComponent = ({
  hasError = false,
  errorText = "",
  infoText = undefined,
  tooltipText = undefined,
  labelText = "",
  id = "dropdown-select",
  value,
  onChange,
  name = "",
  placeholder,
  optionalText,
  required,
  properties,
  disabled,
  options,
}: SelectProps) => {
  const onValueChange = (value: any) => {
    if (onChange) {
      onChange([{ value: value, key: id, properties }]);
    }
  };

  const renderOption = (option: any) => {
    return typeof option === 'string' ? option : t(option.key, option.value);
  };

  const renderSelectedValue = (option: any) => {
    return typeof option === 'string' ? option : t(option.key, option.value);
  };

  const valueAccessor = (option: any) => {
    return typeof option === 'string' ? option : option.value;
  }

  return (
    <Col gridGap={"S300"} className="colContainer">
      <InputWrapper
        labelText={labelText}
        id={id}
        tooltipText={tooltipText}
        error={hasError}
        footer={hasError ? typeof errorText === 'string' ? errorText : t(errorText.key, errorText.value) : infoText}
        dataTestId={`input-wrapper-${id}`}
        required={isEmpty(optionalText)}
        renderLabel={() => (
          <Row alignItems="center" gridGap={"S300"}>
            <Label fontSize="T200" color="accent2" htmlFor={id}>
              <Row gridGap={8}>
                <Text>
                  {labelText}
                  {required && <span color="negative" aria-hidden="true"> *</span>}
                </Text>
              </Row>
              {!isEmpty(optionalText) && <Text>{optionalText}</Text>}
            </Label>
          </Row>
        )}
      >
        {inputProps => (
          <Row gridGap={"S300"}>
            <Select
              {...inputProps}
              id={id}
              aria-label={name}
              aria-describedby={`${inputProps["aria-describedby"]}`}
              width="100%"
              name={name}
              defaultValue={value}
              value={value}
              onChange={onValueChange}
              dataTestId={`select-test-id-${id}`}
              placeholder={placeholder}
              options={options}
              valueAccessor={valueAccessor}
              renderOption={renderOption}
              renderNativeOption={renderOption}
              renderSelectedValue={renderSelectedValue}
            />
          </Row>
        )}
      </InputWrapper>
    </Col>
  )
}

export default SelectComponent;
